import React,{ useMemo } from "react"
import { graphql } from "gatsby"
// import IndexBlcok from "@blocks/Index"
import Seo from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { ProductsList } from "@projects/ProductsList"
import * as css from "@css/layouts/l-products.module.styl"
import { MarqueePageTitle } from "@projects/MarqueePageTitle"
import _ from "lodash"

//カテゴリページ
function ProductsPage({ pageContext, ...props })
{
    const { categorysTopPost } = pageContext
    const { catMenu, posts, categorys } = props.data

    const list = useMemo(() => {
        const result = {}
        const result2 = []
        const menuData = catMenu.menuItems.nodes
        const cats = categorys.edges
        const margedList = []
        for(let i in posts.edges){
            const { node } = posts.edges[i]
            node.cat.nodes.map(({slug,name})=>{
                if( !result[name] ){
                    result[name] = []
                }
                result[name].push(node)
            })
        }
        menuData.map((v)=>{
            cats.map(({node})=>{
                if( v.label === node.name){
                    margedList.push({slug:node.slug, posts: result[node.name],...v})
                }
            })
        })
        console.log("rrr",margedList)
        return margedList
    },[])

    return(
        <>
            <Seo/>
            <Spacer.Header/>
            <main className={css.lProducts}>
                <MarqueePageTitle title="Search"/>
                <div className={css.aInner}>
                    <ProductsList data={list}/>
                </div>
            </main>
        </>
    )
}

export const pagerQuery = graphql`
    query {
        posts : allWpPost(
            filter: {acf_item: {isHide: {ne: true}}}
            sort: {order: DESC, fields: date}
            limit: 9999
        ) {
            edges {
                node {
                    ...Item
                }
            }
        }
        catMenu: wpMenu(name: {eq: "トップとカテゴリページ"}) {
            menuItems {
              nodes {
                id
                label
                order
              }
            }
        }
        categorys : allWpCategory {
            edges {
                node {
                    slug
                    name
                }
            }
        }
    }
`
export default ProductsPage