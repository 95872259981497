import React, { useMemo } from "react"
// import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-marquee-page-title.module.styl"
// import Tags from "@projects/Tags"
// import { Image } from "@atoms/Image"

function MarqueePageTitle({ title = 'emptiy'}) {

    const cloneText = useMemo(()=>{
        let tx = ''
        for(let i = 0; i < 50; i ++){
            tx += ` ${title}`
        }
        return tx
    },[title])

    return (
        <div className={css.pMarqueePageTitle}>
            <div className={css.aInner}>
                <div className={css.aMarquee}>
                    <p>{cloneText}</p>
                </div>
            </div>
        </div>
    )
}

export { MarqueePageTitle }