// extracted by mini-css-extract-plugin
export var aArrow1 = "Zc";
export var aArrow2 = "_c";
export var aButton1 = "Jc";
export var aButton2 = "Kc";
export var aButton3 = "Lc";
export var aButton4 = "Mc";
export var aButton5 = "Nc";
export var aButtonHeaderLogin = "Oc";
export var aButtonHeaderRegister = "Pc";
export var aButtonHeaderSpLogin = "Sc";
export var aButtonLogin = "Rc";
export var aButtonRegister = "Qc";
export var aEmpty = "Gc";
export var aTagAnnounce = "Wc";
export var aTagClose = "Vc";
export var aTagEntry = "Tc";
export var aTagFree = "Yc";
export var aTagLottery = "Xc";
export var aTagPayment = "Uc";
export var aText = "ad";
export var aTitle = "Hc";
export var animationMarquee = "bd";
export var pProductsList = "Ic";