// extracted by mini-css-extract-plugin
export var aArrow1 = "Sb";
export var aArrow2 = "Tb";
export var aButton1 = "Cb";
export var aButton2 = "Db";
export var aButton3 = "Eb";
export var aButton4 = "Fb";
export var aButton5 = "Gb";
export var aButtonHeaderLogin = "Hb";
export var aButtonHeaderRegister = "Ib";
export var aButtonHeaderSpLogin = "Lb";
export var aButtonLogin = "Kb";
export var aButtonRegister = "Jb";
export var aEmpty = "xb";
export var aInner = "Ab";
export var aMarquee = "Bb";
export var aTagAnnounce = "Pb";
export var aTagClose = "Ob";
export var aTagEntry = "Mb";
export var aTagFree = "Rb";
export var aTagLottery = "Qb";
export var aTagPayment = "Nb";
export var aText = "Ub";
export var aTitle = "yb";
export var animationMaquee = "Vb";
export var animationMarquee = "Wb";
export var pMarqueePageTitle = "zb";