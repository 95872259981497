import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-products-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"

function ProductsList({ data }) {
    const q = useStaticQuery(graphql`
        query {
            dammyItem : file(relativePath: { eq: "Common__img__empty.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 240
                        placeholder: BLURRED
                        formats: [AUTO]
                        quality: 100
                        layout: FULL_WIDTH
                    )
                }
            }
        }
    `)
    console.log("s=>", data)
    // data.sort((a,b)=>{
    //     if(a.node.cat.nodes[0].slug === 'other') return 1
    //     if(a.node.cat.nodes[0].slug < b.node.cat.nodes[0].slug) return -1
    //     if(a.node.cat.nodes[0].slug > b.node.cat.nodes[0].slug) return 1
    //     return 0
    // })
    return (
        <div className={css.pProductsList}>
            {data.length === 0 ?
                <div className={css.aEmpty}>
                    <h5 className={css.aTitle}>Empty</h5>
                    <p className={css.aText}>まだ投稿がありません。</p>
                </div>
                :
                <ul>
                    {data.map(({slug, label, posts}, i) => {
                        console.log("list===>", posts)
                        if( !posts || posts.length < 0 ){
                            return null
                        }
                        const { thumbnail } = posts[0]
                        return (
                            <li key={i}>
                                <TransitionLink className={css.aInner} to={`/items/${slug}/`}>
                                    { thumbnail ?
                                        <Image data={thumbnail.node.localFile.childImageSharp} />
                                        :
                                        <Image data={q.dammyItem.childImageSharp} />
                                    }
                                    <p className={css.aTitle}>{label}</p>
                                </TransitionLink>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

export { ProductsList }